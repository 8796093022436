<template>
<div>
    <v-toolbar flat>
        <v-btn icon @click="$router.go(-1)">
            <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{item.name}}</v-toolbar-title>
    </v-toolbar>
    <el-container>
        <el-main>
            <v-row justify="end">
                <v-col cols="12" sm="12" md="8" lg="6">
                    <el-input suffix-icon="el-icon-search" v-model="moduleSearch" clearable placeholder="Search">
                        <!-- <template slot="prepend">
                            <el-button @click="moduleDialog= true"><i class="el-icon-plus"></i> Add Module</el-button>
                        </template> -->
                    </el-input>
                </v-col>
            </v-row>
            <v-row justify="start">
                <v-col v-for="(systemModule) in filterOrgModules" :key="systemModule.id" cols="12" sm="12" md="6" lg="4">
                    <v-card>
                        <v-card-title style="font-size: 16px">{{systemModule.name}}</v-card-title>
                        <v-card-subtitle>Module</v-card-subtitle>
                        <v-card-text>
                            <v-list dense subheader>
                                <v-card flat v-for="route in systemModule.routes" :key="route.id">
                                    <v-card-title style="font-size: 16px">{{route.route.name}}
                                        <v-spacer></v-spacer>
                                        <v-btn-toggle v-if="route.route.routeTabs.length==0" dense text rounded v-model="route.access">
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" outlined @click="route.access='none', createRoleAccess(null, route.route.id, 'none')" value="none" small :color="route.access=='none'?'red':'grey'">
                                                        <v-icon small :color="route.access=='none'?'red':'grey'">block</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span style="font-size: 12px">No Access</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" outlined @click="route.access='read', createRoleAccess(null, route.route.id, 'read')" value="read" small :color="route.access=='read'?'blue':'grey'">
                                                        <v-icon small :color="route.access=='read'?'blue':'grey'">visibility</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span style="font-size: 12px">Read Only</span>
                                            </v-tooltip>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" outlined @click="route.access='write', createRoleAccess(null, route.route.id, 'write')" value="write" small :color="route.access=='write'?'green':'grey'">
                                                        <v-icon small :color="route.access=='write'?'green':'grey'">check_circle</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span style="font-size: 12px">Read/Write</span>
                                            </v-tooltip>
                                        </v-btn-toggle>
                                    </v-card-title>
                                    <v-card-text class="my-0 py-0">
                                        <v-list dense class="my-0 py-0">
                                            <v-list-item tile dense style="border-top: 1px solid lightgrey" v-for="tab in route.route.routeTabs" :key="tab.id">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{tab.name}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn-toggle dense text rounded v-model="tab.access">
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn v-bind="attrs" v-on="on" outlined @click="tab.access='none', createRoleAccess(tab.id, route.route.id, 'none')" value="none" small :color="tab.access=='none'?'red':'grey'">
                                                                    <v-icon small :color="tab.access=='none'?'red':'grey'">block</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span style="font-size: 12px">No Access</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn v-bind="attrs" v-on="on" outlined @click="tab.access='read', createRoleAccess(tab.id, route.route.id, 'read')" value="read" small :color="tab.access=='read'?'blue':'grey'">
                                                                    <v-icon small :color="tab.access=='read'?'blue':'grey'">visibility</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span style="font-size: 12px">Read Only</span>
                                                        </v-tooltip>
                                                        <v-tooltip right>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn v-bind="attrs" v-on="on" outlined @click="tab.access='write', createRoleAccess(tab.id, route.route.id, 'write')" value="write" small :color="tab.access=='write'?'green':'grey'">
                                                                    <v-icon small :color="tab.access=='write'?'green':'grey'">check_circle</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span style="font-size: 12px">Read/Write</span>
                                                        </v-tooltip>
                                                    </v-btn-toggle>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </el-main>
    </el-container>
</div>
</template>

<script>
export default {
    data: () => ({
        item: {},
        moduleSearch: '',
        orgModules: [],
        roleAccess: []
    }),
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(value) {
                if (value) {
                    this.loadRole(value)
                }
            }
        },
    },
    computed: {
        filterOrgModules() {
            let result = this.orgModules
            if (this.moduleSearch) {
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.moduleSearch.toLowerCase())
                )
            }
            return result
        }
    },
    methods: {
        checkAccess(tab) {
            let find = this.roleAccess.find(x => x.routeTabId == tab.id)
            return find ? find.access : 'none'
        },
        checkRouteAccess(route) {
            let find = this.roleAccess.find(x => x.routeId == route.id && x.routeTabId == null)
            return find ? find.access : 'none'
        },
        async createRoleAccess(id, routeId, access) {
            await this.$API.createRoleAccessAdmin({
                roleId: this.item.id,
                routeId: routeId,
                routeTabId: id,
                access: access
            })
        },
        async loadRole(id) {
            this.item = await this.$API.getRoleDetailsAdmin(id)
            await this.getRoleAccess(this.item.id)
            this.getOrganisationModules(this.item.organisationId)
        },
        async getOrganisationModules(id) {
            let data = await this.$API.getOrganisationModuleAdmin(id)
            console.log(data)
            for (let i = 0; i < data.length; i++) {
                console.log(data[i])
                data[i].routes = await this.$API.getModuleRoute(data[i].id)
                for (let j = 0; j < data[i].routes.length; j++) {
                    if (data[i].routes[j].route.routeTabs.length == 0) {
                        data[i].routes[j].access = this.checkRouteAccess(data[i].routes[j].route)
                    }
                    for (let k = 0; k < data[i].routes[j].route.routeTabs.length; k++) {
                        data[i].routes[j].route.routeTabs[k].access = this.checkAccess(data[i].routes[j].route.routeTabs[k])
                    }
                }
            }
            console.log(data)
            this.orgModules = data
        },
        async getRoleAccess(id) {
            this.roleAccess = await this.$API.getRoleAccessAdmin(id)
        }
    }
}
</script>
